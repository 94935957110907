import React from "react";
import { Navigate } from "react-router-dom";

import Landing from "../Pages/Landing/LandingPage";
import Introduction from "../Pages/Introduction/";
import AboutUs from "../Pages/Company/AboutUs";
import OurTeam from "../Pages/Company/OurTeam";
import ContactUs from "../Pages/Contact";
import Services from "../Pages/services";

const PublicRoutes = [
  { path: "/introduction", component: <Landing /> },
  { path: "/", component: <Introduction /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/our-team", component: <OurTeam /> },
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/services", component: <Services /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Navigate to="/" /> },
];

const authProtectedRoutes = [
  // Authentication Page
];

export { authProtectedRoutes, PublicRoutes };

import React from "react";
import { Container, Row, Col } from "reactstrap";
function OfficeAutomation() {
  return (
    <Container id="OfficeAutomation">
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://cdn.iconscout.com/icon/premium/png-256-thumb/automation-1402356-1185341.png?f=webp"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div>
            <p>
              <h1 style={{ fontWeight: "bold" }} className="hero-text">
                Office Automation
              </h1>
              <p className="hero-text" style={{ fontWeight: "300" }}>
                We provide office automation solutions to streamline your
                business processes.
              </p>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OfficeAutomation;

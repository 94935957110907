import Header from "../../Components/Header";
import { Container } from "reactstrap";

const AboutUs = () => {
  return (
    <>
      <Header first={"About"} second={"Desiderata"} />

      <Container
        style={{ backgroundColor: "", marginTop: "10rem" }}
        className=""
      >
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "9rem",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/elementor/thumbs/bg-01-free-img-pwrw8p7ol86izh9lggy64f672yhk1ttpgnwrzad3au.png"
              alt="build"
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
          <div style={{ flex: "1" }}>
            <div>
              <h6 className="hero-text fw-bolder">
                {" "}
                Aiming To Be The{" "}
                <span
                  style={{ color: "#59b907" }}
                  className="hero-text fw-bolder"
                >
                  Best
                </span>{" "}
              </h6>
              <h1 className="hero-text " style={{ fontWeight: "500" }}>
                Desiderata
              </h1>
              <hr
                style={{ border: "2px solid black" }}
                className="w-50 text-dark"
              />
            </div>
            <div>
              <p className="" style={{ lineHeight: "40px" }}>
                <i>
                  {" "}
                  Located in Accra, Desiderata Information Systems Limited
                  operated under the corporate name of 4i Solutions, which was
                  established in 2000 and incorporated at the Ghana Registrar
                  General’s Department in 2006. The company is a
                  well-established African Information Communication Technology
                  and Transformational Business Management Outfit. We offer a
                  diverse and innovative portfolio of services with our core
                  business being; Mobile App Development, Web Design, Web Site
                  Development, Search Engine Optimisation and Positioning, Email
                  Marketing Services, Information Systems Survey technology and
                  software, Computerised Accounting Systems, Project Management,
                  Information Systems Training and Development, Information
                  Systems Staffing Solutions, office Automation, Hardware and
                  Networking Services, Software Based PBX system. Shrewd
                  thinking, creating, doing, that's what being innovative is all
                  about. We at Desiderata work with our customers to provide
                  bespoke, well-engineered services that optimize their total
                  system. As a budding ICT and Management solutions company we
                  continue to add services to our portfolio that anticipate our
                  customers' ever-growing needs. Since our clients are the most
                  valuable asset of our business, we go to great length to build
                  long term relationship with them. We leverage our expertise in
                  a variety of markets to give our customers the competitive
                  advantage in their marketplace while decreasing their total
                  system cost. Our global outlook combined with local
                  responsiveness ensures that our clients remain a step ahead of
                  their competition. Look closer at what Desiderata inside
                  advantage can do for you. Our international web design,
                  website development, web System development, search engine
                  optimisation and email marketing portfolio are really
                  extensive and impressive. Our client base cuts across small to
                  large organizations within both the public and private
                  sectors. We also have a philosophy of contributing back to
                  society by offering huge discount services to Not-for-Profit
                  organisations.
                </i>
              </p>
            </div>
          </div>
        </div>
      </Container>

      <Container
        style={{
          backgroundColor: "",
          marginTop: "10rem",
          position: "relative",
          top: "11.5rem",
        }}
        className=""
      >
        <div className="d-flex justify-content-center">
          {" "}
          <div style={{ width: "80%" }}>
            <div
              className="d-flex p-5"
              style={{
                justifyContent: "space-around",
                columnGap: "15rem",
                flexWrap: "wrap",
                backgroundColor: "#59b907",
              }}
            >
              <div style={{ width: "100px" }}>
                <div>
                  <h6 className="hero-text fw-bolder"> About </h6>
                  <h1 className="hero-text " style={{ fontWeight: "500" }}>
                    Desiderata
                  </h1>
                </div>
              </div>
              <div style={{ flex: "1" }}>
                <div>
                  <p className="hero-text" style={{ fontWeight: "500" }}>
                    Kulweb Technologies is a trusted provider of managed
                    services and product based solutions.We use our collective
                    experience which spans decades.
                  </p>
                  <hr
                    style={{ border: "1px solid black" }}
                    className="w-100 text-dark"
                  />
                </div>
                <div className="mt-5">
                  <p className="hero-text" style={{ fontWeight: "500" }}>
                    Kulweb Technologies is a trusted provider of managed
                    services and product based solutions.We use our collective
                    experience which spans decades.
                  </p>
                  <hr
                    style={{ border: "1px solid black" }}
                    className="w-100 text-dark"
                  />
                </div>
                <div className="mt-5">
                  <p className="hero-text" style={{ fontWeight: "500" }}>
                    Kulweb Technologies is a trusted provider of managed
                    services and product based solutions.We use our collective
                    experience which spans decades.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <div
        style={{
          backgroundColor: "#f4f6f4",
          position: "relative",
          zIndex: "-1",
          bottom: "0",
          top: "-1rem",
          height: "200px",
        }}
      ></div>
    </>
  );
};

export default AboutUs;

import React from "react";
import { Container } from "reactstrap";
import "./Introduction.css";

function Blog() {
  return (
    <>
      <div className="" style={{ marginTop: "10rem" }} data-aos="fade-right">
        <Container>
          <div className="text-center">
            <h4 className="hero-text fw-bolder">Blog</h4>
          </div>

          <div className=" p-2">
            <div
              className="gap-2 row"
              style={{ justifyContent: "space-between", flexWrap: "wrap" }}
            >
              <div
                className="windows-hero col-xl-8"
                style={{
                  border: "1px solid #e0e0e0",
                  display: "flex",
                }}
              >
                <div>
                  <div className="micro-tech">TECHNOLOGY</div>
                  <Container
                    className="p-5 windows-hero-content text-start"
                    fluid
                  >
                    <div className="" style={{ marginTop: "19rem" }}>
                      <h2
                        style={{ color: "#fff" }}
                        className="fw-bolder hero-text mt-5"
                      >
                        Microsoft Teams On Web Gets Feature Update
                      </h2>
                    </div>
                  </Container>
                </div>
              </div>

              <div
                className="micro-hero col-xl-4 "
                style={{
                  border: "1px solid #e0e0e0",
                  flex: "1",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <div

                // style={{ position: "relative", width: "100%" }}
                >
                  <div className="micro-tech">TECHNOLOGY</div>
                  <Container className=" micro-hero-content text-start" fluid>
                    <div className="" style={{ marginTop: "19rem" }}>
                      <h6
                        style={{ color: "#fff" }}
                        className="fw-bolder hero-text mt-5"
                      >
                        Microsoft Quietly Fixes ShadowCoerce Windows NTLM Relay
                        Bug
                      </h6>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-center"
            style={{
              flexWrap: "wrap",
              columnGap: "10px",
              flexDirection: "row",
            }}
          >
            <div className="micro-com" style={{ width: "100%" }}>
              <div className="micro-tech">TECHNOLOGY</div>
              <h6
                style={{ color: "#fff" }}
                className="fw-bolder hero-text mt-5 text-start mx-2"
              >
                Microsoft Teams Adds Free Communities Feature To Take On
                Facebook And Discord
              </h6>
            </div>
            <div className="micro-code " style={{ width: "100%" }}>
              <div className="micro-tech">TECHNOLOGY</div>
              <h6 className="fw-bolder hero-text mt-5 text-start mx-2">
                Stay On The Forefront Of Coding With This Certification Bundle
              </h6>
            </div>
            <div className="micro-sec " style={{ width: "100%" }}>
              <div className="micro-tech">TECHNOLOGY</div>
              <h6 className="fw-bolder hero-text mt-5 text-start mx-2">
                Microsoft Links Holy Ghost Ransomware Operation To North Korean
                Hackers
              </h6>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Blog;

import React from "react";
import Hero from "./Hero";
import Customers from "./Customers";
import Partners from "./partners";
import Service from "./Service";
import Blog from "./Blog";

function index() {
  return (
    <>
      <Hero />
      <Customers />
      <Service />
      <Blog />
      <Partners/>
    </>
  );
}

export default index;

import React from "react";
import { Container, Row, Col } from "reactstrap";

function WebDesign() {
  return (
    <Container id="WebDesign">
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://static.thenounproject.com/png/5110219-200.png"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Web Design
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              We specialize in designing customer-centric outsourced web
              solutions.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default WebDesign;

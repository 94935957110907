import React from "react";
import { Container, Row, Col } from "reactstrap";

function HardwareAndNetworking() {
  return (
    <Container id = "HardwareAndNetworking">
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
            Hardware and Networking Services

            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
            We offer hardware and networking services with a focus on unified communication solutions.


            </p>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://i.pinimg.com/564x/2f/be/51/2fbe51e449c94c4087b5eee10208dd3e.jpg"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default HardwareAndNetworking;

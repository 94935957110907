import Header from "../../Components/Header";
import { Container } from "reactstrap";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const ContactUs = () => {
  const position = [5.6037, -0.187];

  return (
    <>
      <Header first={"Contact"} second={"Us"} />

      <Container
        style={{ backgroundColor: "", marginTop: "7rem" }}
        className=""
      >
        <div className="text-center mb-5">
          <h1 className="hero-text " style={{ fontWeight: "500" }}>
            Get In Touch
          </h1>
          <div className="d-flex justify-content-center"></div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ columnGap: "15px" }}
        >
          <div
            style={{
              width: "34px",
              height: "34px",
              backgroundColor: "#59b907",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            className="p-1 d-flex justify-content-center align-items-center"
          >
            <FaTwitter className="text-light " style={{ fontSize: "15px" }} />
          </div>
          <div
            style={{
              width: "34px",
              height: "34px",
              backgroundColor: "#59b907",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            className="p-1 d-flex justify-content-center align-items-center"
          >
            <FaLinkedinIn className="text-light" style={{ fontSize: "15px" }} />
          </div>
          <div
            style={{
              width: "34px",
              height: "34px",
              backgroundColor: "#59b907",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            className="p-1 d-flex justify-content-center align-items-center"
          >
            <FaInstagram className="text-light" style={{ fontSize: "15px" }} />
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "600px" }}></div>
        </div>

        <div
          className="d-flex mb-5 "
          style={{
            columnGap: "10px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              flex: "1",
              border: "",
              borderRadius: "6px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 32px 80px -40px",
            }}
            className="py-3 px-4"
          >
            <div>
              <div
                style={{
                  width: "34px",
                  height: "34px",
                  backgroundColor: "#f4f6f4",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                className="p-1 d-flex justify-content-center align-items-center mt-2 mb-3 "
              >
                <FaLocationDot
                  className=" "
                  style={{ fontSize: "15px", color: "#59b907" }}
                />
              </div>
            </div>
            <div className="mt-4">
              <h3 className="hero-text" style={{ fontWeight: "400" }}>
                Our Location
              </h3>
            </div>
            <div className="mt-4 mb-5">
              <p
                className="hero-text"
                style={{ fontWeight: "300", marginBottom: "0px" }}
              >
               12 Kakradmadu Road East Cantonments 
              </p>
              <p
                className="hero-text"
                style={{ fontWeight: "300", marginBottom: "0px" }}
              >
                P. O. Box YK 1424 Kanda Accra Ghana – West Africa
              </p>
            </div>
          </div>
          <div
            style={{
              flex: "1",
              border: "",
              borderRadius: "6px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 32px 80px -40px",
            }}
            className="py-3 px-4"
          >
            <div>
              <div
                style={{
                  width: "34px",
                  height: "34px",
                  backgroundColor: "#f4f6f4",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                className="p-1 d-flex justify-content-center align-items-center mt-2 mb-3 "
              >
                <MdOutlineMail
                  className=" "
                  style={{ fontSize: "15px", color: "#59b907" }}
                />
              </div>
            </div>
            <div className="mt-4">
              <h3 className="hero-text" style={{ fontWeight: "400" }}>
                Email Us
              </h3>
            </div>
            <div className="mt-4">
              <p
                className="hero-text mb-5"
                style={{ fontWeight: "300", marginBottom: "0px" }}
              >
                info@desiderata.com.gh
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div
        className="mt-5"
        style={{ marginTop: "10rem", position: "relative", top: "4rem" }}
      >
        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            <Popup>Accra, Ghana</Popup>
          </Marker>
        </MapContainer>
      </div>
    </>
  );
};

export default ContactUs;

import React from "react";
import { Container, Row, Col } from "reactstrap";

function AccountingSystem() {
  return (
    <Container id="AccountingSystem">
      <Row className="d-flex align-items-center">
      <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/1875/1875408.png"
              alt="website design Link"
              style={{height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
            Computerised Accounting Systems

            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
            Our priority is safeguarding your business with our computerized accounting systems.
            </p>
          </div>
        </Col>
     
      </Row>
    </Container>
  );
}

export default AccountingSystem;

import React from "react";
import { Container, Row, Col } from "reactstrap";
function MobileAppDevelopment() {
  return (
    <Container id="MobileAppDevelopment">
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Mobile App Development
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              We develop customer-centric outsourced solutions for mobile
              applications
            </p>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://cdn-icons-png.freepik.com/512/5738/5738077.png"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MobileAppDevelopment;

import React from "react";
import introCustomer from "../../assets/images/intoCustomer.jpg";
import dots from "../../assets/images/dots.png";

function Customers() {
  return (
    <>
      <div className="mb-5" style={{ marginTop: "7rem" }}>
        <div
          className="d-flex justify-content-between mt-5"
          style={{ flexWrap: "wrap", columnGap: "70px" }}
        >
          <div
            style={{ width: "400px" }}
            className="mx-5"
            data-aos="fade-right"
          >
            <img
              src={dots}
              alt="dots"
              className="img-fluid"
              width="350px"
            ></img>
          </div>
          {/* <div
            style={{ width: "400px", position: "relative", left: "7rem" }}
            data-aos="fade-left"
          >
            <img
              src={introCustomer}
              alt="intoCustomer"
              className="img-fluid"
              width="400px"
              style={{
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.5) 16px 40px 80px -32px",
              }}
            ></img>
          </div> */}

          <div
            className="d-flex gap-5 mb-5 align-items-center"
            style={{
              flex: "1",
              position: "relative",
              top: "1rem",
              flexWrap: "wrap",

              backgroundColor: "#59b907",
            }}
            data-aos="fade-left"
          >
            <div>
              <img
                src={introCustomer}
                alt="intoCustomer"
                className="img-fluid"
                width="400px"
                style={{
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.5) 16px 40px 80px -32px",
                  position: "absolute",
                  top: "-5rem",
                  left: "-4rem",
                }}
              ></img>

              <img
                src={introCustomer}
                alt="intoCustomer"
                className="img-fluid"
                width="400px"
                style={{
                  borderRadius: "10px",

                  boxShadow: "rgba(0, 0, 0, 0.5) 16px 40px 80px -32px",
                }}
              ></img>
            </div>

            <div style={{ paddingRight: "-10rem", flex: "1" }}>
              <div>
                <h2 style={{}} className="fw-bolder hero-text text-light mx-3">
                  Our Customers
                </h2>
              </div>
              <div className="mt-4 mx-4">
                <p
                  className="fs-5 hero-text text-light"
                  style={{ fontWeight: "300" }}
                >
                  We leverage our expertise in a variety of markets to give our
                  customers the competitive advantage in their marketplace while
                  decreasing their total system cost. Our global outlook
                  combined with local responsiveness ensures that our clients
                  remain a step ahead of their competition. Look closer at what
                  Desiderata inside advantage can do for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customers;

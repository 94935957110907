import React from "react";
import { Container, Button, Card } from "reactstrap";

function Service() {
  const imageStyle = {
    display: "block",
    maxWidth: "100%",
    height: "auto",

    boxShadow: "rgba(255, 165, 0, 0.2) 10px 10px 30px",
  };

  const containerStyle = {
    display: "inline-block",
    position: "relative",
  };
  const card = [
    {
      img: "https://kulwebtech.com/wp-content/uploads/2022/09/Html5-300x300.png",
      title: "Web Site Development",
      description:
        "Our experts help create fast, responsive & smooth websites on our Microsoft Azure platform.",
    },
    {
      img: "https://cdn-icons-png.freepik.com/512/5738/5738077.png",
      title: "Mobile App Development",
      description:
        "We develop customer-centric outsourced solutions for mobile applications.",
    },
    {
      img: "https://static.thenounproject.com/png/5110219-200.png",
      title: "Web Design",
      description:
        "We specialize in designing customer-centric outsourced web solutions.",
    },
    {
      img: "https://cdn-icons-png.freepik.com/512/5738/5738077.png",
      title: "Project Management",
      description:
        "Our project management solutions are tailored to meet our customers' needs.",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQsJDTbi0--qZCRjFcrveQkpmjmg0C8B3uRA&s",
      title: "Information Systems Training and Development",
      description:
        " We offer comprehensive training and development solutions for information systems.",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6vT2lxB1Poaa_sTf1ZPugFH5lfZ5AEnnm3w&s",
      title: " Information Systems Staffing Solutions",
      description:
        "Our staffing solutions ensure the right fit for your information systems needs.",
    },
    {
      img: "https://cdn.iconscout.com/icon/premium/png-256-thumb/automation-1402356-1185341.png?f=webp",
      title: "Office Automation",
      description:
        "We provide office automation solutions to streamline your business processes.",
    },
    {
      img: "https://i.pinimg.com/564x/3f/2e/fb/3f2efb20f5de51b45557cb0c7d9f80d7.jpg",
      title: "Search Engine Optimisation and Positioning",
      description:
        "We optimize your online presence using cloud computing resources for enhanced visibility.",
    },
    {
      img: "https://cdn-icons-png.flaticon.com/512/1875/1875408.png",
      title: "Computerised Accounting Systems",
      description: " Our priority is safeguarding your business with our computerized accounting systems.",
    },
    {
      img: "https://i.pinimg.com/564x/2f/be/51/2fbe51e449c94c4087b5eee10208dd3e.jpg",
      title: "Hardware and Networking Services",
      description: "We offer hardware and networking services with a focus on unified communication solutions.",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST_uDaCbCz6SqOvVxeCQNn99fIPAADetKwpA&s",
      title: "Software Based PBX system",
      description:
        "Our expertise ensures tailored design and implementation of software-based PBX systems.",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtNjAIC6p8SlCmd6uh70-WvZJvHfk48kWiIjAru2zwOg&s",
      title: "Email Marketing Services",
      description:
        " We provide specialized email marketing services tailored to your needs.",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQL2WDl1pKIq3Oxmy0euc2-5mU-ScTym3SUhy0_BZL2aw&s",
      title: "Information Systems Survey technology and software",
      description:
        "Our expertise covers a wide range of technologies for designing and implementing survey technology and software solutions.",
    },
  ];

  return (
    <>
      <div
        className="services-hero"
        style={{ position: "absolute", top: "130rem", zIndex: "-2111" }}
      ></div>
      <div className="">
        <div className="">
          <div className="services-hero-overlay"></div>
          <Container className="p-5 services-hero-content text-dark">
            <div
              className="text-center fw-bolder"
              style={{ marginTop: "13rem", color: "black" }}
              data-aos="zoom-in-up"
            >
              <p
                style={{ fontFamily: "arial", fontSize: "3rem" }}
                className="hero-text"
              >
                Our Services
              </p>
              <div>
                <Button
                  style={{ backgroundColor: "#59b907", border: "none" }}
                  className=" px-5 py-3 text-start"
                >
                  Find Out More
                </Button>
              </div>
            </div>

            <div className="mt-5">
              <div
                className="d-flex justify-content-around"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  columnGap: "10px",
                }}
              >
                {card?.map((item, key) => {
                  return (
                    <div
                      className="mt-5 "
                      style={{ width: "320px" }}
                      key={key}
                      data-aos="fade-right"
                    >
                      <Card className="service-card px-4">
                        <div className="text-start">
                          <img
                            src={item.img}
                            alt="html"
                            className="img-fluid mt-5"
                            width="70"
                            height={"70"}
                          ></img>
                          <h5
                            className="hero-text mt-3"
                            style={{ fontWeight: "300" }}
                          >
                            {item.title}
                          </h5>
                          <p
                            className="hero-text mt-3"
                            style={{ fontWeight: "200" }}
                          >
                            {item.description}
                          </p>
                        </div>
                        <div
                          className="text-start"
                          style={{ color: "#59b907" }}
                        >
                          Learn More
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className=""
              style={{ marginTop: "10rem" }}
              data-aos="fade-left"
            >
              <div
                className="d-flex align-items-center"
                style={{
                  flexWrap: "wrap",
                  flexDirection: "row",
                  columnGap: "20px",
                }}
              >
                <div style={{ width: "700px" }} className="text-start">
                  <h4 className="hero-into hero-text fw-bolder">
                    The "IT" Factor
                  </h4>
                  <p className="  hero-text fs-5" style={{ fontWeight: "300" }}>
                    <i>
                      Our goal is to disseminate existing innovations into
                      useable solutions which can be implemented for our
                      customers usage, helping them to see the value from
                      technology within their environments.
                    </i>
                  </p>
                </div>
                <div style={{ flex: "1" }}>
                  <img
                    src="https://kulwebtech.com/wp-content/uploads/2022/09/Kulweb-Technologies_Business-Profile-2021-e1663598484472.jpg"
                    alt="timeline"
                    style={imageStyle}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Service;

import React from "react";
import { Container, Row, Col } from "reactstrap";

function InfoSystemsSurvey() {
  return (
    <Container id="InfoSystemsSurvey">
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Information Systems Survey technology and software
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              Our expertise covers a wide range of technologies for designing
              and implementing survey technology and software solutions.
            </p>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQL2WDl1pKIq3Oxmy0euc2-5mU-ScTym3SUhy0_BZL2aw&s"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default InfoSystemsSurvey;

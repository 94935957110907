import { APIClient } from "./api_hepler";

import * as url from "./url_helper";

const api = new APIClient();

export const feedsAPICALL = () =>
  api.get(
    "https://reuters-business-and-financial-news.p.rapidapi.com/category-id/238/article-date/2024-01-10"
  );

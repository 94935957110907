import React from "react";
import { Container, Col, Row } from "reactstrap";
function WebsiteDevelopment() {
  return (
    <Container id="WebsiteDevelopment">
      <Row className="d-flex align-items-center">
        <Col xs="6" md="6">
          <div className="text-center">
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/09/Html5-300x300.png"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Web Site Development
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              Our experts help create fast, responsive & smooth websites on our
              Microsoft Azure platform.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default WebsiteDevelopment;

import React from "react";
import { Container, Row, Col } from "reactstrap";

function EmailMarketing() {
  return (
    <Container id="EmailMarketing">
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtNjAIC6p8SlCmd6uh70-WvZJvHfk48kWiIjAru2zwOg&s"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Email Marketing Services
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              We provide specialized email marketing services tailored to your
              needs.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EmailMarketing;

import React from "react";
import { Container, Col, Row } from "reactstrap";

function SystemsStaffing() {
  return (
    <Container id="SystemsStaffing">
      <Row className="d-flex align-items-center">
      <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Information Systems Staffing Solutions
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              Our staffing solutions ensure the right fit for your information
              systems needs.
            </p>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6vT2lxB1Poaa_sTf1ZPugFH5lfZ5AEnnm3w&s"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
      
      </Row>
    </Container>
  );
}

export default SystemsStaffing;

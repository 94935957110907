import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { HashLink } from "react-router-hash-link";

function NavbarComponent() {
  const menus = [
    { to: "/", content: "Home" },
    {
      to: "/about-us",
      content: "Company",
      submenus: [
        { to: "/about-us", content: "About Us" },
        { to: "/our-team", content: "Our Team" },
      ],
    },
    {
      to: "/services",
      content: "Services",
      submenus: [
        {
          // to: "/services/#WebsiteDevelopment",
          innerContent: " Data Center And Could Solutions",
          innerSubmenus: [
            {
              to: "/services/#HardwareAndNetworking",
              content: " Hardware and Networking Services",
            },
          ],
        },
        {
          // to: "/services/#WebsiteDevelopment",
          innerContent: " Software Solutions",
          innerSubmenus: [
            {
              to: "/services/#WebsiteDevelopment",
              content: " Web Site Development",
            },
            {
              to: "/services/#MobileAppDevelopment",
              content: "Mobile App Development",
            },
            { to: "/services/#WebDesign", content: "Web Design" },
            {
              to: "/services/#ProjectManagement",
              content: "Project Management",
            },
            {
              to: "/services/#SysTrainingDevelopment",
              content: "  Information Systems Training and Development",
            },
            {
              to: "/services/#SystemsStaffing",
              content: " Information Systems Staffing Solutions",
            },
            { to: "/services/#OfficeAutomation", content: "Office Automation" },
            {
              to: "/services/#SEO",
              content: " Search Engine Optimisation and Positioning",
            },
            {
              to: "/services/#AccountingSystem",
              content: "  Computerised Accounting Systems",
            },

            { to: "/services/#PBX", content: " Software Based PBX system" },
            {
              to: "/services/#InfoSystemsSurvey",
              content: "  Information Systems Survey Technology and Software",
            },
            {
              to: "/services/#EmailMarketing",
              content: " Email Marketing Services",
            },
          ],
        },
        {
          // to: "/services/#WebsiteDevelopment",
          innerContent: " Hardware And Networking",
          innerSubmenus: [
            {
              to: "/services/#HardwareAndNetworking",
              content: " Hardware and Networking Services",
            },
            { to: "/services/#PBX", content: " Software Based PBX system" },
          ],
        },
      ],
    },
    { to: "/contact-us", content: "Contact" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  const toggleDropdown = (index) => {
    setDropdownOpen((prevState) => (prevState === index ? null : index));
  };

  const handleMouseEnter = (index) => {
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  const [innerDropdownOpen, setInnerDropdownOpen] = useState(null);

  const toggleInnerDropDown = (index) => {
    setInnerDropdownOpen((prevstate) => (prevstate === index ? null : index));
  };

  const handleInnerMouseEnter = (index) => {
    setInnerDropdownOpen(index);
  };

  const handleInnerMouseLeave = () => {
    setInnerDropdownOpen(null);
  };

  return (
    <Navbar
      // color="dark"
      className="py-3 px-3"
      dark
      expand="md"
      style={{ backgroundColor: "rgba(37, 39, 36, 0.5)" }}
    >
      {/* <NavbarBrand href="/">Your Logo</NavbarBrand> */}
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav
          className="ml-auto"
          navbar
          // style={{ backgroundColor: "rgba(37, 39, 36, 0.5)" }}
        >
          {menus.map((menu, index) => (
            <React.Fragment key={index}>
              {menu.submenus ? (
                <Dropdown
                  isOpen={dropdownOpen === index}
                  toggle={() => toggleDropdown(index)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  nav
                  inNavbar
                >
                  <DropdownToggle nav caret>
                    {menu.content}
                  </DropdownToggle>

                  <DropdownMenu
                    style={{ backgroundColor: "rgba(37, 39, 36, 0.9)" }}
                  >
                    {menu.submenus.map((submenu, subIndex) => (
                      <DropdownItem
                        key={subIndex}
                        href={submenu.to}
                        style={{ color: "gray" }}
                        // tag={
                        //   submenu.to.startsWith("/services")
                        //     ? HashLink
                        //     : NavLink
                        // }
                        // smooth
                        // to={
                        //   submenu.to.startsWith("/services") ? submenu.to : null
                        // }
                      >
                        {submenu.innerContent ? (
                          <Dropdown
                            isOpen={innerDropdownOpen === subIndex}
                            toggle={toggleInnerDropDown}
                            direction={"start"}
                            onMouseEnter={() => handleInnerMouseEnter(subIndex)}
                            onMouseLeave={handleInnerMouseLeave}
                          >
                            <DropdownToggle
                            nav
                              caret
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "gray",
                              }}
                            >
                              {submenu.innerContent}
                            </DropdownToggle>
                            <DropdownMenu
                              style={{
                                backgroundColor: "rgba(37, 39, 36, 1)",
                              }}
                            >
                              {submenu?.innerSubmenus?.map((item, key) => {
                                return (
                                  <DropdownItem
                                    key={key}
                                    href={item.to}
                                    style={{
                                      color: "gray",
                                      // backgroundColor: "transparent",
                                    }}
                                    tag={
                                      item.to.startsWith("/services")
                                        ? HashLink
                                        : NavLink
                                    }
                                    smooth
                                    to={
                                      item.to.startsWith("/services")
                                        ? item.to
                                        : null
                                    }
                                  >
                                    {item.content}
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        ) : (
                          submenu.content
                        )}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <NavLink href={menu.to}>{menu.content}</NavLink>
              )}
            </React.Fragment>
          ))}
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default NavbarComponent;

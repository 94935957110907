import { all } from "redux-saga/effects";

// Import other slice sagas as needed

import feedsSaga from "../app/features/feeds/saga";

export default function* rootSaga() {
  yield all([
 
    feedsSaga(),
    // Add other sagas here
  ]);
}

import React from "react";
import Header from "../../Components/Header";
import WebsiteDevelopment from "./WebSiteDevelopment";
import MobileAppDevelopment from "./MobileAppDevelopment";
import WebDesign from "./WebDesign";
import ProjectManagement from "./ProjectManagement";
import SysTrainingDevelopment from "./SysTrainingDevelopment";
import InfoSystemsSurvey from "./InforSystemsSurvey";
import SystemsStaffing from "./SystemsStaffingSolutions";
import OfficeAutomation from "./OfficeAutomation";
import SEO from "./SEO";
import AccountingSystem from "./AccountingSystems";
import HardwareAndNetworking from "./HardwareAndNetworking";
import PBX from "./PBX";
import EmailMarketing from "./EmailMarketing";

function Index() {
  return (
    <>
      <Header first={"Our"} second={"Services"} />
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
          gap: "20rem",
          marginTop: "10rem",
          marginBottom: "10rem",
        }}
      >
        <WebsiteDevelopment />
        <MobileAppDevelopment />
        <WebDesign />
        <ProjectManagement />
        <SysTrainingDevelopment />

        <SystemsStaffing />
        <OfficeAutomation />
        <SEO />
        <AccountingSystem />
        <HardwareAndNetworking />
        <PBX />

        <InfoSystemsSurvey />
        <EmailMarketing />
      </div>
    </>
  );
}

export default Index;

import Header from "../../Components/Header";
import { Container } from "reactstrap";

const OurTeam = () => {
  return (
    <>
      <Header first={"Our"} second={"Team"} />

      <Container
        style={{ backgroundColor: "", marginTop: "10rem" }}
        className=""
      >
        <div className="text-center mb-5">
          <h1 className="hero-text " style={{ fontWeight: "500" }}>
            {" "}
            The{" "}
            <span style={{ color: "#59b907" }} className="hero-text fw-bolder">
              Brains
            </span>{" "}
            Behind Desiderata Information Systems
          </h1>
          <div className="d-flex justify-content-center">
            <hr
              style={{ border: "0.8px solid black", width: "150px" }}
              className=" text-dark"
            />
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"350"}
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text"
                style={{ lineHeight: "40px", fontWeight: "300" }}
              >
                Lead Consultant M & E
              </p>
              <p
                className="hero-text fw-bolder text-center"
                style={{ lineHeight: "40px", marginRight: "15rem" }}
              >
                Percy Anaab Bukari
              </p>
            </div>
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text text-end"
                style={{ lineHeight: "40px", fontWeight: "300" }}
              >
                Lead ICT & Project Manager
              </p>
              <p
                className="hero-text fw-bolder text-center"
                style={{ lineHeight: "40px", marginLeft: "15rem" }}
              >
                Samuel Odoi Laryea
              </p>
            </div>
          </div>
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"350"}
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"350"}
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text"
                style={{ lineHeight: "40px", fontWeight: "300" }}
              >
                Legal and DevOp Lead
              </p>
              <p
                className="hero-text fw-bolder text-center"
                style={{ lineHeight: "40px", marginRight: "15rem" }}
              >
                Mr. Justice Nditsi
              </p>
            </div>
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text text-end"
                style={{ lineHeight: "40px", fontWeight: "300" }}
              >
                Finance and Admin
              </p>
              <p
                className="hero-text fw-bolder text-center"
                style={{ lineHeight: "10px", marginLeft: "15rem" }}
              >
                Mr. Prince Khartey
              </p>
            </div>
          </div>
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"350"}
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"350"}
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text"
                style={{ lineHeight: "40px", fontWeight: "300" }}
              >
                Developer
              </p>
              <p
                className="hero-text fw-bolder text-center"
                style={{ lineHeight: "40px", marginRight: "15rem" }}
              >
                Mr. Solomon Oka
              </p>
            </div>
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text text-end"
                style={{ lineHeight: "40px", fontWeight: "300" }}
              >
                Photography and Graphic Design
              </p>
              <p
                className="hero-text fw-bolder text-center"
                style={{ lineHeight: "10px", marginLeft: "15rem" }}
              >
                Mr. Samir Dekowski
              </p>
            </div>
          </div>
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"350"}
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
        </div>
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"350"}
              style={{ marginLeft: "10rem" }}
            ></img>
          </div>
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text"
                style={{ lineHeight: "40px", fontWeight: "300" }}
              >
                Administrator and HR
              </p>
              <p
                className="hero-text fw-bolder text-center"
                style={{ lineHeight: "40px", marginRight: "15rem" }}
              >
                Ms. Juliana Siw
              </p>
            </div>
          </div>
        </div>

        {/* last image */}
        <div
          className="d-flex "
          style={{
            justifyContent: "center",
            columnGap: "2rem",
            flexWrap: "wrap",
            marginTop: "10rem",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: "1", position: "relative", bottom: "0" }}>
            <div style={{ position: "relative", bottom: "0" }}>
              <p
                className="hero-text text-start fs-3"
                style={{ lineHeight: "40px", fontWeight: "600" }}
              >
                From The CEO's Desk
              </p>
              <p
                className="hero-text  text-start mt-5"
                style={{ lineHeight: "40px", fontWeight: "400" }}
              >
                <i>
                  Lucky Bogoshi, was born in Sandton, Johannesburg where he
                  attended school at St. John’s College. He matriculated at St.
                  John’s College in 2006 to further his studies in IT
                  Engineering. He completed his Microsoft Certified Systems
                  Engineer (MCSE) course in 2009 and later that year was
                  employed by Mthombo IT (MIT) as a call center consultant.
                  Later in 2010, he was head hunted by Hewlett Packard (HP) to
                  work for their IT transformation team for their customer Anglo
                  America in Witbank, Mpumalanga. He held the position of IT
                  Team Leader. In 2012, he joined another company called
                  Netology where he was a Remote Support Specialist, which
                  helped him to further his career within the Microsoft
                  technologies which were being implemented for their customer
                  base. The same year, Lucky moved over to Netsurit as an
                  Intermediate Technical Consultant where he was based on the
                  road and visited customers daily for all their various
                  technical issues.
                </i>
              </p>
            </div>
          </div>
          <div style={{ width: "600px" }}>
            <img
              src="https://kulwebtech.com/wp-content/uploads/2022/10/IMG_724024.png"
              alt="build"
              width={"650"}
              style={{ marginLeft: "0rem" }}
            ></img>
            <p
              className="hero-text  text-end mt-5"
              style={{ lineHeight: "40px", fontWeight: "400" }}
            >
              Later in 2015, Lucky was promoted to US Technical Support Team
              Leader, where he spent over 2 months in New York to meet the
              customers and assist them with their IT challenges, he had a team
              based in South Africa who reported to him. Lucky was also assigned
              another role within Netsurit as Service Delivery Manager for one
              of their customers called Reckitt Benckiser who had their own team
              of IT consultants who reported to Lucky. Lucky was assigned more
              responsibilities at Netsurit and took over an Onsite Team Leader
              role to manage more customers in Johannesburg. Essentially with a
              balance of South African & US customers which Lucky was managing
              with his team of technical consultants. Lucky left Netsurit in
              2018 to start his own company Kulweb Technologies where he holds
              the Director position and has a wealth of customers that he has
              been building over the years. Lucky has a vast amount of technical
              experience especially with Cloud technologies such as Microsoft
              365, Microsoft Azure and Amazon Web Services (AWS).
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OurTeam;

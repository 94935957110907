import React from "react";
import { Container, Row, Col } from "reactstrap";
function SysTrainingDevelopment() {
  return (
    <Container id="SysTrainingDevelopment">
      <Row className="d-flex align-items-center">
      <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbB4zUmULaLifas3sS1lehExHuQI7tx0kBHQ&s"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Information Systems Training and Development
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              We offer comprehensive training and development solutions for
              information systems.
            </p>
          </div>
        </Col>
       
      </Row>
    </Container>
  );
}

export default SysTrainingDevelopment;

import React from "react";
import Navbar from "./InforSystems/Navbar";
import { Container, Button } from "reactstrap";
import logo from "../assets/images/img.png";

function Header({ first, second }) {
  const path = window.location.pathname;

  console.log(path, "pth");

  //   "teams-hero"
  return (
    <>
      <div
        className={
          path === "/about-us"
            ? "header-hero"
            : path === "/our-team"
            ? "teams-hero"
            : path === "/contact-us"
            ? "contact-hero"
            : path === "/services/"
            ? "our-services-hero"
            : ""
        }
      >
        <div className="header-hero-overlay"></div>
        <Container className="p-5 header-hero-content">
          <div className="d-flex justify-content-between">
            <div style={{ cursor: "pointer", color: "#fff" }}>
              <img
                style={{ width: "11rem", borderRadius: "50px" }}
                src={logo}
                alt="logo"
              />
            </div>
            <div style={{ color: "#fff" }}>
              <Navbar />
            </div>
          </div>
          <div
            className="text-start fw-bolder"
            style={{ marginTop: "7rem", color: "#fff" }}
          >
            <p style={{ fontFamily: "arial", fontSize: "4.5rem" }} className="">
              {first}
              <span style={{ fontFamily: "arial", color: "#59b907" }}>
                {" "}
                {second}{" "}
              </span>{" "}
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Header;

import { combineReducers } from 'redux';

import feeds from '../app/features/feeds/reducer'

// Import other slice reducers as needed

const rootReducer = combineReducers({

  Feeds: feeds
  
 
  // Add other slices here
});

export default rootReducer;

import React, { useEffect } from "react";
import { Container, Button } from "reactstrap";
import logo from "../../assets/images/img.png";
import Navbar from "../../Components/InforSystems/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";

function Hero() {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 2000,
  //     once: true,
  //   });
  //   AOS.refresh();
  // }, []);

  return (
    <>
      <div className="intro-hero">
        <div className="intro-hero-overlay"></div>
        <Container className=" intro-hero-content">
          <div className="d-flex justify-content-between mt-5">
            <div style={{ cursor: "pointer", color: "#fff" }}>
              <img
                style={{ width: "11rem", borderRadius: "50px" }}
                src={logo}
                alt="logo"
              />
            </div>
            <div style={{ color: "#fff" }}>
              <Navbar />
            </div>
          </div>

          <div
            className="d-flex "
            style={{ justifyContent: "flex-start", flexWrap: "wrap" }}
          >
            <div
              className="text-start fw-bolder"
              style={{ marginTop: "13rem", color: "#fff" }}
              data-aos="fade-up"
            >
              <p
                style={{
                  fontFamily: "arial",
                  fontSize: "4rem",
                  textAlign: "left",
                }}
                className=""
              >
                <span style={{ fontFamily: "arial", color: "#59b907" }}>
                  {" "}
                  We Make <br />
                </span>
                Technology Work <br />
                <span style={{ fontFamily: "arial", color: "#59b907" }}>
                  For{" "}
                </span>{" "}
                You
              </p>
              <div data-aos="zoom-in">
                <Button
                  style={{ backgroundColor: "#59b907", border: "none" }}
                  className=" px-5 py-3 text-start "
                >
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: "black", color: "white" }}>
        <div
          className="d-flex p-4 justify-content-around"
          style={{ flexWrap: "wrap" }}
        >
          <div className="text-center" data-aos="zoom-in">
            <p
              style={{ fontSize: "8rem", color: "#00ab44" }}
              className="fw-lighter hero-text"
            >
              30
            </p>
            <p className="fw-bolder hero-text">Happy Clients</p>
          </div>
          <div className="text-center" data-aos="zoom-in">
            <p
              style={{ fontSize: "8rem", color: "#00ab44" }}
              className="fw-lighter hero-text"
            >
              320
            </p>
            <p className="fw-bolder hero-text">Project Completed</p>
          </div>
          <div className="text-center" data-aos="zoom-in">
            <p
              style={{ fontSize: "8rem", color: "#00ab44" }}
              className="fw-lighter hero-text"
            >
              100%
            </p>
            <p className="fw-bolder hero-text">Positive Feedback</p>
          </div>
          <div className="text-center" data-aos="zoom-in">
            <p
              style={{ fontSize: "8rem", color: "#00ab44" }}
              className="fw-lighter hero-text"
            >
              1000
            </p>
            <p className="fw-bold hero-text">Cups Of Coffee</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;

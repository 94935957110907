import React, { useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import AOS from "aos";
import Footer from "./Components/InforSystems/Footer";
import { ToastContainer } from "react-toastify";
import { PublicRoutes } from "./Routes/allRoutes";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getFeedsAction } from "./store/rootAction";
import { AppContext } from "./helpers/AppContext";
import { BarLoader } from "react-spinners";
import img from "./assets/images/img.png";
import "./assets/styles/global.css";

function App() {
  const dispatch = useDispatch();
  const { contentLoaded, markContentAsLoaded } = useContext(AppContext);

  useEffect(() => {
    // Dispatch your actions (e.g., fetching data) here
    dispatch(getFeedsAction());
  }, [dispatch]);

  // Simulate loading time (Replace with your actual content loading logic)
  useEffect(() => {
    const loadContent = async () => {
      // Simulate loading time (Replace with your actual content loading logic)
      await new Promise((resolve) => setTimeout(resolve, 5000));
      markContentAsLoaded();
    };

    loadContent();
  }, [markContentAsLoaded]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Routes>
        {PublicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
      </Routes>
      <Footer />

      {/* {contentLoaded ? (
        <>
          <Routes>
            {PublicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Routes>
          <Footer />
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-grid align-items-center">
            <div>
              <img src={img} alt="img" className="img-fluid" width="300" />
            </div>
            <div className="text-center mt-4 d-flex justify-content-center">
              <BarLoader color={"#000000"} size={50} />
            </div>
          </div>
        </div>
      )} */}
    </Router>
  );
}

export default App;

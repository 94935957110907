import React from "react";
import { Container, Row, Col } from "reactstrap";

function SEO() {
  return (
    <Container id="SEO">
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <div>
            <h1 style={{ fontWeight: "bold" }} className="hero-text">
              Search Engine Optimisation and Positioning
            </h1>
            <p className="hero-text" style={{ fontWeight: "300" }}>
              We optimize your online presence using cloud computing resources
              for enhanced visibility.
            </p>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="text-center">
            <img
              src="https://i.pinimg.com/564x/3f/2e/fb/3f2efb20f5de51b45557cb0c7d9f80d7.jpg"
              alt="website design Link"
              style={{ height: "auto" }}
              width={"300px"}
              height={"300px"}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SEO;
